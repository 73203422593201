import { Fragment } from 'react';
import chroma from 'chroma-js';
import { getStyle } from './StyleUtility';
import { Text, View } from '@react-pdf/renderer';

export const NOTE_EXPORT_TYPES = {
    PDF: 'pdf',    
    JSON: 'json',     // Add JSON export type
    MARKDOWN: 'markdown',
    CSV: 'csv'  // Add CSV export type
};

export class ExportLibrary {

    static renderPDFNoteContent = (noteJSON, keys, styles) => {
        if (!Array.isArray(noteJSON)) {
            return <Text style={styles.noteText}>{noteJSON}</Text>;
        }

        return (
            <View style={{ 
                flexDirection: 'row', 
                flexWrap: 'wrap',
                gap: 2,
                alignItems: 'center'  // Add this to vertically center all items
            }}>
                {noteJSON.map((element, index) => {
                    switch (element.type) {
                        case "note":
                            return (
                                <Text key={index} style={[
                                    styles.noteText,
                                    { margin: 0 }  // Ensure no extra margins
                                ]}>
                                    {element.value}
                                </Text>
                            );
                        case "mention":
                            const Key = keys.find((x) => x.Id === element.TagId);
                            const TextColor = Key?.Color
                                ? chroma.contrast(Key.Color, "white") > 5
                                    ? "white"
                                    : "black"
                                : "#000000";
                            
                            return (
                                <View 
                                    key={index} 
                                    style={[
                                        styles.tag,
                                        { 
                                            backgroundColor: Key?.Color || '#e0e0e0',
                                        }
                                    ]}
                                >
                                    <Text style={[
                                        styles.noteText,
                                        { color: TextColor }
                                    ]}>
                                        {element.value}
                                    </Text>
                                </View>
                            );
                        default:
                            return <Text style={styles.noteText}>{element.value}</Text>;
                    }
                })}
            </View>
        );
    }

    static formatNoteToJSON = (notes, keys) => {
        // If notes is not an array of notes, but rather a single note's content
        if (!Array.isArray(notes) || (notes.length > 0 && !notes[0].Content)) {
            return {
                type: "campaign",
                content: notes
            };
        }

        // Process array of notes
        return notes.map(note => ({
            id: note.Id,
            title: note.Title,
            createdDate: note.CreatedDate,
            content: Array.isArray(note.Content) 
                ? note.Content.map(element => {
                    switch (element.type) {
                        case "note":
                            return {
                                type: "note",
                                content: element.value
                            };
                        case "mention":
                            const key = keys.find((x) => x.Id === element.TagId);
                            return {
                                type: "mention",
                                content: element.value,
                                tagId: element.TagId,
                                tagColor: key?.Color || '#e0e0e0',
                                tagMetadata: key || null
                            };
                        default:
                            return {
                                type: "unknown",
                                content: element.value
                            };
                    }
                })
                : note.Content
        }));
    }

    // Keep the original renderNoteContent for other uses (like preview)
    static renderNoteContent = (noteJSON, ContentType, keys) => {
        switch(ContentType) {
            case 1:
                return noteJSON.map((element, index) => {
                    switch (element.type) {
                        case "note":
                            return (
                                <span key={index}>
                                    {element.value.split('\n').map((line, i) => (
                                        <Fragment key={i}>
                                            {line}
                                            {i < element.value.split('\n').length - 1 && <br />}
                                        </Fragment>
                                    ))}
                                </span>
                            );
                        case "mention":
                            let Key = keys.find((x) => x.Id == element.TagId);
                            let TextColor = Key?.Color
                                ? chroma.contrast(Key?.Color, "white") > 5
                                    ? "white"
                                    : "black"
                                : "var(--text-primary)";
                            return (
                                <span
                                    key={index}
                                    className={"tag p-[0.2rem] rounded " + getStyle(Key)}
                                    style={{
                                        backgroundColor: Key?.Color,
                                        color: TextColor,
                                    }}
                                    tagid={element.TagId}
                                    tagtype={element.type}
                                >
                                    {element.value}
                                </span>
                            );
                    }
                });          
            case 2:
                return <img src={noteJSON} alt='Note Image' className='w-1/6 h-1/6' />;
            default: 
                return "Unable to render note content."
        }
    }

    static saveAsJSON = (noteJSON, keys, filename = 'notes', startDate = null) => {
        const jsonData = this.formatNoteToJSON(noteJSON, keys);
        const blob = new Blob([JSON.stringify(jsonData, null, 2)], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        
        link.href = url;
        link.download = `${filename}.json`;
        document.body.appendChild(link);
        link.click();
        
        // Cleanup
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    static saveAsMarkdown = (notes, keys, filename = 'notes', startDate = null) => {
        const markdownContent = this.convertToMarkdown(notes, keys, startDate);
        const blob = new Blob([markdownContent], { type: 'text/markdown' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        
        link.href = url;
        link.download = `${filename}.md`;
        document.body.appendChild(link);
        link.click();
        
        // Cleanup
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    static convertToMarkdown = (notes, keys, startDate) => {
        // If notes is not an array of notes, but rather a single note's content
        if (!Array.isArray(notes)) {
            return this.convertNoteContentToMarkdown(notes, keys);
        }

        // Add title and date only once at the beginning
        const firstNote = notes[0];
        const dateStr = new Date(startDate).toLocaleDateString();
        let markdown = `# ${firstNote.Title}\n`;
        markdown += `*Created on ${dateStr}*\n\n`;

        // Sort notes by their timestamp in NoteText
        const sortedNotes = [...notes].sort((a, b) => {
            const aContent = JSON.parse(a.NoteText);
            const bContent = JSON.parse(b.NoteText);
            return aContent.timestamp - bContent.timestamp;
        });

        // Get unique session IDs and create a mapping to sequential indices
        const uniqueSessionIds = [...new Set(sortedNotes.map(note => note.SessionId))].sort((a, b) => a - b);
        const sessionIndexMap = Object.fromEntries(uniqueSessionIds.map((id, index) => [id, index]));

        // Group notes by session index
        const sessions = sortedNotes.reduce((acc, note) => {
            const sessionIndex = sessionIndexMap[note.SessionId] || 0;
            if (!acc[sessionIndex]) {
                acc[sessionIndex] = [];
            }
            acc[sessionIndex].push(note);
            return acc;
        }, {});

        // Process notes by session
        markdown += Object.entries(sessions).map(([sessionIndex, sessionNotes]) => {
            let sessionContent = `## Session ${parseInt(sessionIndex) + 1}\n\n`;
            sessionContent += sessionNotes.map(note => {
                const jsonNoteContent = JSON.parse(note.NoteText);
                return this.convertNoteContentToMarkdown(jsonNoteContent, keys);
            }).join('\n\n');
            return sessionContent;
        }).join('\n\n---\n\n');

        return markdown;
    }

    static convertNoteContentToMarkdown = (content, keys) => {
        return content.map(element => {
            switch (element.type) {
                case "note":
                    return element.value;
                case "mention":
                    const key = keys.find(x => x.Id === element.TagId);
                    // Format mentions with both color and bold
                    return `<span style="color: ${key?.Color || '#e0e0e0'}">**${element.value}**</span>`;
                default:
                    return element.value;
            }
        }).join('');
    }

    static saveAsCSV = (data, filename = 'notes') => {
        const csvContent = this.convertToCSV(data);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        
        link.href = url;
        link.download = `${filename}.csv`;
        document.body.appendChild(link);
        link.click();
        
        // Cleanup
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    static convertToCSV = (data, startDate) => {
        // Headers for the CSV
        const headers = ['NoteText', 'Session Number', 'Session Title', 'Sort Order', 'NoteTypeId', 'Note Type Name', 'ContentType'];
        let csvContent = headers.join(',') + '\n';

        // Process each note
        data.forEach(note => {
            // Prepare each field, escaping as needed
            const fields = [
                this.escapeCSVField(note.NoteText || ''),               // NoteText
                note.SessionNumber || '',                               // Session Number
                this.escapeCSVField(note.Title || ''),                  // Session Title
                note.SortOrder || '',                                   // Sort Order
                note.NoteTypeId || '',                                  // NoteTypeId
            this.escapeCSVField(note.Name || ''),                       // Note Type Name
                note.ContentType || ''                                  // ContentType
            ];

            // Join fields with commas and add newline
            csvContent += fields.join(',') + '\n';
        });

        return csvContent;
    }

    static escapeCSVField = (field) => {
        // Convert to string if not already
        const str = String(field);
        
        // If the field contains quotes, commas, or newlines, wrap in quotes and escape existing quotes
        if (str.includes('"') || str.includes(',') || str.includes('\n')) {
            return `"${str.replace(/"/g, '""')}"`;
        }
        return str;
    }
} 