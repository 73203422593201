export const getStyle = (state) => {
    if(!state)
        return;

    let styleClasses = [];

    if (state.Style & 1) styleClasses.push("font-bold");
    if (state.Style & 2) styleClasses.push("italic");
    if (state.Style & 4) styleClasses.push("text-decoration-line: underline");

    if (state.BorderStyle & 1) styleClasses.push("border-2 border-solid border-text-primary");
    if (state.BorderStyle & 2) styleClasses.push("border-4 border-double border-text-primary");
    if (state.BorderStyle & 4) styleClasses.push("border-2 border-dashed border-text-primary");
    if (state.BorderStyle & 8) styleClasses.push("border-2 border-dotted border-text-primary");

    return styleClasses.join(" ");
}; 