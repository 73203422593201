import React, { Fragment, useEffect, useState, Suspense, lazy } from 'react';
import { useQuery } from "@tanstack/react-query";
import { useCampaignContext } from "../contexts/CampaignContext";
import { useNavigate, useParams } from "react-router-dom";
import ReactGA from 'react-ga4';
import moment from 'moment';
import { Dialog, Transition } from '@headlessui/react';
import Loading from '../components/Atoms/Loading';
import Button from '../components/Atoms/Button';
import Modal from '../components/Molecules/Modal';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import EmptyStateCampaign from '../components/Molecules/EmptyStateCampaign';
import { PERMISSIONS } from '../utilities/Constants';
import { useStripeContext } from '../contexts/StripeContext';
import { ExportLibrary, NOTE_EXPORT_TYPES } from '../utilities/ExportLibrary';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import PDFTemplate from '../components/Exporting/PDFTemplate';

const PageHeader = lazy(() => import('../components/Infrastructure/PageHeader'));
const SessionList = lazy(() => import("../components/Organisms/SessionList"));
const Legend = lazy(() => import("../components/Organisms/Legend"));
const QuestList = lazy(() => import("../components/Organisms/QuestList"));
const AdvancedSearch = lazy(() => import("../components/Organisms/AdvancedSearch"));
const TagManager = lazy(() => import("../components/Organisms/TagManager"));

export default function Campaign() {
    const campaignContext = useCampaignContext();
    const { currentCampaignId, setCurrentCampaignId, getCampaign, getKeys, getNoteTypes, getKeyWords, addSession, getSessions, getExportData, useExportData } = campaignContext;
    const { campaignId } = useParams();
    const CampaignId = parseInt(campaignId);
    
    let navigate = useNavigate();
    
    const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
    const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
    const [exportFormat, setExportFormat] = useState("csv");
    const [currentCampaign, setCurrentCampaign] = useState(null);
    const [activeTab, setActiveTab] = useState("Sessions");
    const [selectedPermission, setSelectedPermission] = useState(PERMISSIONS.Viewer);
    
    const {isLoading : campaignIsLoading, data : campaignData, error: campaignError} = useQuery({ queryKey: ["Campaign"], queryFn: () => getCampaign(CampaignId)});
    const SessionsQuery = useQuery({ queryKey: ["Sessions", CampaignId ?? 0], queryFn: () => getSessions(CampaignId)});
    const KeysQuery = useQuery({ queryKey: ["Keys"], queryFn: () => getKeys(CampaignId)});
    const NoteTypesQuery = useQuery({ queryKey: ["NoteTypes", CampaignId ?? 0], queryFn: () => getNoteTypes(CampaignId)});
    const KeyWordsQuery = useQuery({ queryKey: ["Keywords"], queryFn: () => getKeyWords(CampaignId)});        
    
    const stripeContext = useStripeContext();
    let { hasAccess } = stripeContext;

    const exportMutation = useExportData();

    const ContentMap = {
        Sessions: 
            <>
                {SessionsQuery.data?.length == 0 && <EmptyStateCampaign onClick={(e) =>
                    {
                        addSession(e, CampaignId).then(response => 
                        {
                            let SessionData = response.data;
                            navigate(`/campaigns/${CampaignId}/session/${SessionData[0][0].Id}`)
                        });
                    }
                } />}
                {SessionsQuery.data?.length > 0 && <SessionList Sessions={SessionsQuery.data || []} Keys={KeysQuery.data || []} />}
            </>,
        Legend: <Legend NoteTypesQuery={NoteTypesQuery} KeysQuery={KeysQuery} CampaignId={CampaignId} />,
        QuestList: <QuestList KeysQuery={KeysQuery} NoteTypesQuery={NoteTypesQuery} KeyWordsQuery={KeyWordsQuery} CampaignData={currentCampaign} CampaignId={CampaignId} />,
        Search: <AdvancedSearch KeysQuery={KeysQuery} NoteTypesQuery={NoteTypesQuery} KeyWordsQuery={KeyWordsQuery} />,
        TagManager: <TagManager KeysQuery={KeysQuery} CampaignId={CampaignId} />
    }

    useEffect(() =>
    {
        if (!campaignIsLoading && !campaignError && campaignData)
        {
            setCurrentCampaign(campaignData[0]);
        }
    }, [campaignIsLoading, campaignError, campaignData])

    useEffect(() =>
    {
        if (currentCampaignId === null)
        {
            if (CampaignId != undefined)
            {
                setCurrentCampaignId(CampaignId);
            }
        }
    }, []);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      }, []);
      

    const pageHeaderActions = [
        {
            name: 'New Session', icon: 'Plus', onClick: (e) =>
            {
                addSession(e, CampaignId).then(response => 
                {
                    let SessionData = response.data;
                    navigate(`/campaigns/${CampaignId}/session/${SessionData[0][0].Id}`)
                });
            }, group: false, visibleTo: PERMISSIONS.Editor
        },        
        { 
            name: 'Actions', 
            icon: 'ChevronDown', 
            group: true, 
            visibleTo: PERMISSIONS.Editor,
            dropdown: true,
            menuItems: [
                { 
                    name: 'Invite', 
                    icon: 'UserPlus', 
                    onClick: () => { setIsInviteDialogOpen(true) },
                    disabled: !hasAccess,
                },
                { 
                    name: 'Export', 
                    icon: 'ArrowDownTray', 
                    onClick: () => { setIsExportDialogOpen(true) } 
                }
            ]
        },
        { name: 'Settings', icon: 'Cog', onClick: () => { navigate(`/campaigns/${CampaignId}/edit`) }, group: true, visibleTo: PERMISSIONS.Editor },        
    ];

    const subheaderInfo = [
        // {name: 'Created on', data: moment(currentCampaign?.CreatedDate).add(1, 'd').format("MMMM Do, YYYY"), icon: 'Calendar'},
        {name: 'Began', data: moment(currentCampaign?.StartDate).add(1, 'd').format("MMMM Do, YYYY"), icon: 'Calendar'}
    ];

    const tabs = [
        {name: "Sessions", value: "Sessions", current: activeTab == "Sessions", onClick: () => setActiveTab("Sessions")},
        {name: "Legend", value: "Legend", current: activeTab == "Legend", onClick: () => setActiveTab("Legend")},
        {name: "Check List", value: "QuestList", current: activeTab == "QuestList", onClick: () => setActiveTab("QuestList")},
        {name: "Advanced Search", value: "Search", current: activeTab == "Search", onClick: () => setActiveTab("Search"), mobileDisabled: true, requiresSubscription: true},
        {name: "Tag Manager", value: "TagManager", current: activeTab == "TagManager", onClick: () => setActiveTab("TagManager"), mobileDisabled: true, requiresSubscription: true}
    ];

    const filteredTabs = tabs.filter(tab => !tab.requiresSubscription || hasAccess);

    let InviteLink = `https://inkless.app/campaigns/${CampaignId}/join/${currentCampaign?.InviteCode}/${selectedPermission}`;

    const ExportFormatSelect = () => {
        const exportOptions = [            
            { id: 'pdf', title: 'PDF' },
            { id: 'markdown', title: 'Markdown' },
            { id: 'json', title: 'JSON' },            
            { id: 'csv', title: 'CSV', requiresAccess: true },
        ];

        return (
            <fieldset>                
                <p className="mt-1 text-sm/6 text-gray-600">Choose the format you'd like to export your campaign in.</p>
                <div className="my-4 space-y-2 flex flex-col items-center">
                    {exportOptions.map((option) => (
                        <div 
                            key={option.id} 
                            className={`flex items-center border-2 rounded-md p-2 w-1/2 select-none
                                ${exportFormat === option.id ? 'border-primary' : 'border-border'}
                                ${option.requiresAccess && !hasAccess 
                                    ? 'opacity-50 cursor-not-allowed' 
                                    : 'cursor-pointer hover:border-primary/50'}`}
                            onClick={() => {
                                if (!(option.requiresAccess && !hasAccess)) {
                                    setExportFormat(option.id);
                                }
                            }}
                        >
                            <input
                                id={option.id}
                                name="export-format"
                                type="radio"
                                value={option.id}
                                checked={exportFormat === option.id}
                                onChange={() => {}}
                                disabled={option.requiresAccess && !hasAccess}
                                className="relative h-4 w-4 appearance-none rounded-full border border-gray-300 bg-white 
                                    before:absolute before:left-1/2 before:top-1/2 before:h-2 before:w-2 before:-translate-x-1/2 before:-translate-y-1/2 
                                    before:rounded-full before:bg-white checked:border-primary checked:bg-primary checked:before:bg-white 
                                    disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-100"
                            />
                            <label 
                                htmlFor={option.id} 
                                className={`ml-3 flex items-center gap-2 text-sm/6 font-medium flex-1
                                    ${option.requiresAccess && !hasAccess ? 'cursor-not-allowed text-gray-400' : 'cursor-pointer text-textprimary'}`}
                            >
                                {option.title}
                                {option.requiresAccess && !hasAccess && (
                                    <span className="inline-flex items-center opacity-80 rounded-md bg-primary px-2 py-0.5 text-xs font-medium text-white">
                                        Supporter
                                    </span>
                                )}
                            </label>
                        </div>
                    ))}
                </div>
            </fieldset>
        );
    };

    
    const handleExport = async () => {
        try {
            // Check if user has access to CSV export
            if (exportFormat === NOTE_EXPORT_TYPES.CSV && !hasAccess) {
                alert('CSV export is only available to supporters');
                return;
            }

            const data = await exportMutation.mutateAsync(exportFormat);
            setIsExportDialogOpen(false);
            
            switch (exportFormat) {
                case NOTE_EXPORT_TYPES.PDF:
                    // Generate the PDF document as a Blob
                    const blob = await pdf(
                        <PDFTemplate 
                            notes={data} 
                            keys={KeysQuery.data || []} 
                            campaignName={currentCampaign?.Name || 'Campaign Notes'}
                            campaignStartDate={currentCampaign?.StartDate}
                            noteTypes={NoteTypesQuery.data || []}
                        />
                    ).toBlob();

                    // Trigger a download using file-saver
                    saveAs(blob, 'campaign-notes.pdf');
                    break;

                case NOTE_EXPORT_TYPES.JSON:
                    // Use our new JSON export functionality
                    ExportLibrary.saveAsJSON(
                        data, 
                        KeysQuery.data || [], 
                        `${currentCampaign?.Name || 'campaign'}-notes`,
                        currentCampaign?.StartDate
                    );
                    break;

                case NOTE_EXPORT_TYPES.MARKDOWN:
                    ExportLibrary.saveAsMarkdown(
                        data,
                        KeysQuery.data || [],
                        `${currentCampaign?.Name || 'campaign'}-notes`,
                        currentCampaign?.StartDate
                    );
                    break;

                case NOTE_EXPORT_TYPES.CSV:
                    const startDate = moment(currentCampaign?.StartDate).format('YYYY-MM-DD');
                    ExportLibrary.saveAsCSV(
                        data,
                        `${currentCampaign?.Name || 'campaign'}_${startDate}`
                    );
                    break;

                default:
                    console.warn('Export format not yet implemented:', exportFormat);
                    break;
            }
        } catch (error) {
            console.error('Export failed:', error);
            alert('Export failed. Please try again.');
        }
    };

    return (
        <>
            <Suspense fallback={<Loading />}><PageHeader pageTitle={currentCampaign?.Name} actions={pageHeaderActions} subheader={subheaderInfo} tabs={filteredTabs} tabChange={setActiveTab} campaignId={CampaignId} /></Suspense>
            <div id="CampaignContainer" className="flex flex-row gap-2 sm:px-2 sm:py-2 md:px-4 py-4 justify-center grid-cols-2 overflow-hidden themeBackgroundImage">
                <Suspense fallback={<Loading />}>{ContentMap[activeTab]}</Suspense>                
            </div>

            <Modal
                isOpen={isInviteDialogOpen}
                onClose={() => setIsInviteDialogOpen(false)}
            >
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-textprimary mb-4">
                    Invite to Campaign
                </Dialog.Title>

                <div className="mb-4">
                    <label htmlFor="permission" className="block text-sm font-medium text-textprimary mb-2">
                        Permission Level
                    </label>
                    <select
                        id="permission"
                        className="w-1/2 rounded-md border border-border bg-background text-textprimary px-3 py-2"
                        value={selectedPermission}
                        onChange={(e) => setSelectedPermission(e.target.value)}
                    >
                        <option value={PERMISSIONS.Viewer}>Viewer</option>
                        <option value={PERMISSIONS.Editor}>Editor</option>
                    </select>
                </div>

                <p>Send this invite link to your party members.</p>
                <br />

                <span>{InviteLink}</span>

                <div className="mt-4 flex gap-2 justify-between">
                    <Button icon={DocumentDuplicateIcon} label={"Copy"} onClick={() => { navigator.clipboard.writeText(InviteLink); }} />
                    <Button label={"Close"} bgColor='bg-background' textColor="text-textprimary" border="border-border" onClick={() => setIsInviteDialogOpen(false)} />
                </div>
            </Modal>

            <Modal
                isOpen={isExportDialogOpen}
                onClose={() => setIsExportDialogOpen(false)}
            >
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-textprimary mb-4">
                    Export Campaign
                </Dialog.Title>

                <div className="mb-4">                   
                    {exportMutation.isLoading ? (
                        <div className="flex flex-col items-center justify-center p-4">
                            <Loading />
                            <p className="mt-4 text-sm text-gray-600">Generating your export...</p>
                        </div>
                    ) : (
                        <ExportFormatSelect />
                    )}
                </div>

                <div className="mt-4 flex gap-2 justify-between">
                    <Button 
                        label={exportMutation.isLoading ? "Exporting..." : "Export"} 
                        bgColor='bg-primary' 
                        textColor="text-white" 
                        onClick={handleExport}
                        disabled={exportMutation.isLoading}
                    />
                    <Button 
                        label={"Close"} 
                        bgColor='bg-background' 
                        textColor="text-textprimary" 
                        border="border-border" 
                        onClick={() => setIsExportDialogOpen(false)}
                        disabled={exportMutation.isLoading}
                    />
                </div>
            </Modal>
            

        </>
    )

}