import { useEffect, useState, useRef } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { ListChecks, Keyboard} from "lucide-react";

// Custom hook for fade-in animation
const useIntersectionObserver = (options = {}) => {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
        observer.unobserve(entry.target);
      }
    }, { threshold: 0.1, ...options });

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  return [elementRef, isVisible];
};

const features = [
  {
    name: "Keep track of Quests",
    description:
      "Use your naturally occuring notes to turn them into a check list you can refer back to later.",
    icon: ListChecks,
  },
  {
    name: "Advanced Search",
    description:
      "For when you really need to narrow things down. Filter by tags, note types, and keywords.",
    icon: MagnifyingGlassIcon,
  },
  {
    name: "Tag Manager",
    description: `See how often you use certain tags and update existing ones.`,
    secondDescription:  `(Spelling NPC's names can be hard sometimes...)`,
    icon: Keyboard,
  },
];

export default function SecondaryFeatures() {
  return (
    <div className="bg-primary py-16 border-b border-border">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">          
          <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-white sm:text-4xl text-center">
            A better way to manage your notes
          </p>
          <p className="mt-4 max-w-2xl text-xl text-white/80 lg:mx-auto text-center mx-auto">
            Lots of additional features to make note-taking worth while and easier for you.
          </p>
        </div>

        <div className="mt-16">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
            {features.map((feature) => {
              const [ref, isVisible] = useIntersectionObserver();
              
              return (
                <div
                  key={feature.name}
                  ref={ref}
                  className={`relative bg-gradient-to-b from-card/80 to-card rounded-xl p-8 
                    shadow-[0_0_15px_rgba(255,255,255,0.07)] 
                    border border-white/20 
                    text-center backdrop-blur-sm 
                    transition-all duration-1000 ease-out 
                    hover:scale-105 
                    hover:shadow-[0_0_25px_rgba(255,255,255,0.1)] 
                    hover:border-white/30 
                    hover:ring-[1px] hover:ring-white/20
                    min-w-[280px] ${
                    isVisible 
                      ? 'opacity-100 translate-y-0' 
                      : 'opacity-0 translate-y-10'
                  }`}
                >
                  <dt>
                    <div className="flex h-16 w-16 items-center justify-center rounded-xl bg-accent/90 text-accent mb-6 mx-auto transform -translate-y-12 shadow-lg border-4 border-background">
                      <feature.icon
                        className="h-8 w-8"
                        aria-hidden="true"
                      />
                    </div>
                    <p className="text-2xl font-semibold leading-7 text-white mb-4">
                      {feature.name}
                    </p>
                  </dt>
                  <dd className="text-lg text-white/80">
                    {feature.description}
                    {feature.secondDescription && (
                      <>
                        <br />
                        <span className="text-sm italic text-white/60 mt-2 block">
                          {feature.secondDescription}
                        </span>
                      </>
                    )}
                  </dd>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
