import React, { useState } from "react";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import Modal from "../Molecules/Modal";
import { Dialog } from "@headlessui/react";

export default function Button({
  rounding = "rounded-md",
  bgColor = "bg-primary",
  border = "border-transparent",
  textColor = "text-texttertiary",
  hoverColor = "hover:bg-primaryhover",
  hoverTextColor = "group-hover:text-white",
  icon,
  label,
  onClick,
  id,
  isEnabled = true,
  className = "",
}) {
  const [showPopup, setShowPopup] = useState(false);
  const Icon = icon;

  const handleClick = () => {
    if (isEnabled) {
      onClick();
    } else {
      setShowPopup(true);
    }
  };

  return (
    <>
      <button 
        id={id}
        type="button"
        className={`inline-flex justify-center items-center ${rounding} border ${border} ${bgColor} py-2 px-4 gap-2 text-sm ${textColor} 
        shadow-sm ${hoverColor} group focus:outline-none focus:ring-2 focus:ring-primary-hover focus:ring-offset-2 ${!isEnabled && "cursor-not-allowed opacity-50"} ${className}`}
        onClick={handleClick}        
      >
        {isEnabled ? 
          icon && (
            <div className={`h-5 w-5 text-gray-400`}>
            {<Icon className={`${textColor} ${hoverColor} group-hover:text-white`} />}
          </div>
        ) : (
          <div className={`h-5 w-5 text-gray-400`}>
            <LockClosedIcon className="text-gray-400" />
          </div>
        )}
        <span className={`font-semibold whitespace-nowrap ${hoverTextColor}`}>{label}</span>
      </button>

      {showPopup && (
        <Modal
        isOpen={showPopup}
        onClose={() => setShowPopup(false)}
      >
        <Dialog.Title
          as="h3"
          className="text-lg font-semibold leading-6 text-textprimary mb-4"
        >
          You've reached the free tier limit
        </Dialog.Title>

        <Dialog.Description>
          Upgrade to the Pro plan to help support Inkless and get unlimited access to all features.
        </Dialog.Description>

        <div className="mt-4 flex justify-end gap-2">          
          <Button
            label={"Close"}    
            bgColor='bg-background'        
            textColor="text-textprimary" 
            border="border-border"
            onClick={() => setShowPopup(false)}
          />
          <Button
            label={"Pricing"}
            bgColor='bg-primary'
            textColor="text-texttertiary"
            border="border-primary"
            onClick={() => window.location.href = '/pricing'}
          />
        </div>        
      </Modal>
      )}
    </>
  );
}