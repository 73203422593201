// Libs
import React, { Fragment, useState, useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import Search from "../Organisms/Search";
import ThemeToggle from "../Atoms/ThemeToggle";
// Context
import { useCampaignContext } from "../../contexts/CampaignContext";

// Components
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon, MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import LogoIcon from "../Atoms/LogoIcon";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

//🐉🔍🔑📜🧭⚙️

const navArr = [
  // { name: "Features", href: "/#", current: true },
  // { name: "Pricing", href: "/#", current: false },
];

// Variables
const authedNavArr = [
  { name: "My Campaigns", href: "/campaigns", current: true },  
  { name: "Feedback", href: "/feedback", current: false },  
  { name: "Support Inkless", href: "/pricing", current: false },  
];

const profileNav = [  
  { name: "account", route: "/settings/account" },
  //{ name: "preferences", route: "/settings/preferences" },
  // { name: "billing", route: "/settings/billing" },
];

const TopMenu = () => {
  // Hooks & Context
  const { user, isAuthenticated, getAccessTokenSilently, logout, loginWithRedirect} = useAuth0();
  const location = useLocation();
  const campaignData = useCampaignContext();
  const { currentCampaignId } = campaignData;

  //State
  const [navigation, setNavigation] = useState(navArr);  

  useEffect(() => {
    if (isAuthenticated) {
      setNavigation(authedNavArr);
    }
  }, []);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const NavItem = ({ href, text }) => {
    return (
      <NavLink
        to={href}
        className={({ isActive }) =>
          classNames(
            isActive
              ? "bg-primary text-white hover:bg-secondary-hover hover:text-textaccent hover:bg-accent"
              : "text-textprimary hover:bg-primary hover:text-white",
            "px-3 py-2 rounded-md text-sm font-medium"
          )
        }
      >
        <span className="font-semibold">{text}</span>
      </NavLink>
    );
  };

  return (
    <>
      <Disclosure
        as="nav"
        className="bg-background"
      >
        {({ open, close }) => (
          <>
            <div className="mx-auto px-2 sm:px-4 lg:px-8 border-solid border-b-2 border-border">
              <div className="relative flex h-12 items-center justify-between">
                <div className="flex items-center px-2 lg:px-0">
                  <div className="flex-shrink-0">
                    <NavLink to="/">
                      <LogoIcon className="block h-10 w-auto lg:hidden" />                     
                    </NavLink>
                    <NavLink to="/">
                      <LogoIcon className="hidden h-10 w-auto lg:block" />
                    </NavLink>
                  </div>
                  <div className="hidden md:ml-4 md:block">
                    <div className="flex space-x-4 items-center">
                      {navigation.map((nav) => (
                        <NavItem
                          href={nav.href}
                          text={nav.name}
                          key={nav.name}
                        />
                      ))}
                    </div>
                  </div>
                </div>

                {/* Search */}
                {isAuthenticated && currentCampaignId > 0 && location.pathname !== "/" && <Search />}

                {/* Mobile menu button */}
                <div className="flex gap-2 md:hidden">
                  <ThemeToggle />
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>                    
                    {open ? (
                      <XMarkIcon
                        className="block h-6 w-6 fill-primary"
                        aria-hidden="true"
                      />
                    ) : (
                      <Bars3Icon
                        className="block h-6 w-6 fill-primary"
                        aria-hidden="true"
                      />
                    )}
                  </Disclosure.Button>
                </div>

                {/* User Notifications & Avatar */}
                {user ? (
                  <div className="hidden md:ml-4 md:block">
                    <div className="flex items-center">                      
                      <ThemeToggle />

                      {/* Profile dropdown */}
                      <Menu
                        as="div"
                        className="relative ml-4 flex-shrink-0"
                      >
                        <div>
                          <Menu.Button className="flex rounded-full bg-gray-800 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="sr-only">Open user menu</span>
                            {user && (
                              <img
                                className="h-8 w-8 rounded-full"                                
                                src={user.picture}
                                alt="User Avatar"
                              />
                            )}
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-background py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {profileNav.map((navItem, index) => (
                              <Menu.Item key={index}>
                                {({ active }) => (
                                  <NavLink
                                    to={navItem.route}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 capitalize text-sm text-gray-700"
                                    )}
                                  >
                                    {navItem.name}
                                  </NavLink>
                                )}
                              </Menu.Item>
                            ))}
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  onClick={() =>
                                    logout({ returnTo: window.location.origin })
                                  }
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block cursor-pointer px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  Sign out
                                </a>
                              )}
                            </Menu.Item>                            
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                ) : (
                  <div className="hidden lg:ml-4 md:flex ml-10 space-x-4 items-center">
                    <ThemeToggle />
                    <button                      
                      onClick={() => loginWithRedirect()}
                      className="inline-block rounded-md border border-transparent text-base font-medium text-textprimary hover:bg-opacity-75"
                    >
                      Sign in
                    </button>
                    <button
                      onClick={() => loginWithRedirect({ screen_hint: 'signup' })}
                      className="inline-block rounded-md border border-transparent bg-accent py-2 px-4 text-base font-medium text-textaccent hover:bg-accenthover hover:text-textprimary"
                    >
                      Sign up
                    </button>
                  </div>
                )}
              </div>
            </div>

            {/* Medium & Below Menu */}
            <Disclosure.Panel className="lg:hidden absolute bg-background rounded w-full z-50 shadow">
              <div className="space-y-1 px-2 pt-2 pb-3">                
                {navigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className={({ isActive }) =>
                      classNames(
                        isActive
                          ? "bg-primary text-texttertiary"
                          : "text-gray-300 hover:bg-primary hover:text-texttertiary",
                        "block rounded-md px-3 py-2 text-base font-medium"
                      )
                    }
                    onClick={close}
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
              {user ? (
                <div className="border-t border-gray-700 pt-4 pb-3">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={user.picture}
                        alt="User Avatar"
                      />
                    </div>
                    <div className="ml-3">                     
                      <div className="text-sm font-medium text-gray-400">
                        {user.email}
                      </div>
                    </div>                   
                  </div>
                  <div className="mt-3 space-y-1 px-2">
                    {profileNav.map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.route}
                        className={({ isActive }) =>
                          classNames(
                            isActive
                              ? "bg-primary text-white"
                              : "text-gray-300 hover:bg-primary hover:text-texttertiary",
                            "block rounded-md px-3 py-2 text-base font-medium"
                          )
                        }
                        onClick={close}
                      >
                        {item.name}
                      </NavLink>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col p-2 items-center">
                  <a
                    onClick={() => loginWithRedirect({ screen_hint: 'signup' })}
                    className="flex w-full cursor-pointer items-center justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                  >
                    Sign up
                  </a>
                  <p className="my-6 text-center text-base font-medium text-gray-500">
                    Existing customer?{" "}
                    <a
                      onClick={() => loginWithRedirect()}
                      className="text-accent cursor-pointer hover:text-indigo-500"
                    >
                      Sign in
                    </a>
                  </p>
                </div>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default TopMenu;