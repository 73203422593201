import { useEffect, useState, useRef } from "react";
import { DocumentTextIcon, TagIcon, MagnifyingGlassIcon, PaintBrushIcon, SparklesIcon, BookOpenIcon, PencilIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import LandingMention from './LandingMention';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

import screenshotNotes from "../../images/screenshots/light_theme/Notes2.png";
import screenshotLegend from "../../images/screenshots/light_theme/Legend.png";
import screenshotThemes from "../../images/screenshots/light_theme/Themes1.png";
import gifSearching from "../../images/screenshots/light_theme/Searching.gif";
import memeGif from "../../images/screenshots/light_theme/meme1.gif";
import memeGif2 from "../../images/screenshots/light_theme/meme2.gif";

import screenshotNotesDark from "../../images/screenshots/dark_theme/notes.png";
import screenshotLegendDark from "../../images/screenshots/dark_theme/legend.png";
import screenshotThemesDark from "../../images/screenshots/dark_theme/themes.png";
import screenshotGifsDark from "../../images/screenshots/dark_theme/Searching_dark.gif";

// Custom hook for fade-in animation
const useIntersectionObserver = (options = {}) => {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
        observer.unobserve(entry.target);
      }
    }, { threshold: 0.1, ...options });

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  return [elementRef, isVisible];
};

const ActionButton = ({ children, onClick }) => (
  <button
    onClick={onClick}
    className="mt-6 inline-flex items-center gap-2 rounded-md border border-transparent bg-white px-4 py-2 text-base font-medium text-primary hover:bg-opacity-75 transition-colors"
  >
    {children}
    <ArrowRightIcon className="h-5 w-5" />
  </button>
);

const features = [
  {
    title:  "Easy to use, easy to read",
    subtitle: "Organized Notes",
    description: (
      <>
        Using common <LandingMention bgColor="accent">bullet journal</LandingMention> techniques, <LandingMention bgColor="primary" textColor="white">Inkless</LandingMention> makes your notes look great, easy to read, and work for you by design.
      </>
    ),
    lightImage: screenshotNotes,
    darkImage: screenshotNotesDark,
    imageSize: 'large',
    icon: BookOpenIcon,
    action: "Start writing"
  },
  {
    title: "Organize with ease",
    subtitle: "Tags & Note Types",
    description: (
      <>
        <span className="leading-8">
          Fully customizable <LandingMention bgColor="accent">tags</LandingMention> makes what important to you stand out, like <LandingMention bgColor="accent" isItalic>NPC's</LandingMention>, <LandingMention bgColor="accent" isItalic>locations</LandingMention>, and <LandingMention bgColor="accent" isItalic>items</LandingMention>.
        </span>
        <br />
        <span className="flex items-center gap-2 -ml-7">
          <PencilIcon className="h-5 w-5 text-accent" aria-hidden="true" />
          Note Types let you mark your notes differently.
        </span>
      </>
    ),
    lightImage: screenshotLegend,
    darkImage: screenshotLegendDark,
    imageSize: 'large',
    icon: TagIcon,
    action: "Try it out"
  },
  {
    title: "Find anything instantly",
    subtitle: "Quick Search",
    description: (
      <>
        If there's one thing I've always wanted mid-session, it's this. <LandingMention bgColor="accent">Quickly search</LandingMention> all of your notes from <LandingMention bgColor="accent" isItalic>anywhere</LandingMention> inside your campaign.
      </>
    ),
    lightImage: gifSearching,
    darkImage: screenshotGifsDark,
    imageSize: 'large',
    icon: MagnifyingGlassIcon,
    action: "See how it works"
  },
  {
    title: "Match your style",
    subtitle: "Campaign Themes",
    description: (
      <>
        Choose from multiple <LandingMention bgColor="accent">themes</LandingMention> that fit you or your <LandingMention bgColor="primary" textColor="white">campaign's</LandingMention> style. 
        All themes are made with art from real artists or photographers. <LandingMention bgColor="accent" isItalic>No AI imagery</LandingMention>.
      </>
    ),
    lightImage: screenshotThemes,
    darkImage: screenshotThemesDark,
    imageSize: 'large',
    icon: PaintBrushIcon
  },
  {
    title: "Pronounced Guh-ifs",
    subtitle: "GIFs!",
    description: (
      <>
        Add <LandingMention bgColor="accent" isBold>GIFs</LandingMention> directly to your notes to make your <LandingMention bgColor="accent" isItalic>ridiculous</LandingMention> moments even funnier.
      </>
    ),
    lightImage: [memeGif, memeGif2],
    darkImage: [memeGif, memeGif2],
    imageSize: 'small',
    isMultiImage: true,
    icon: SparklesIcon
  },
];

export default function PrimaryFeatures() {
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    const checkTheme = () => {
      const theme = localStorage.getItem('theme');
      setIsDarkTheme(theme === 'dark');
    };

    checkTheme();
    window.addEventListener('themeChanged', checkTheme);

    return () => {      
      window.removeEventListener('themeChanged', checkTheme);
    };
  }, []);

  const handleAction = () => {
    if (isAuthenticated) {
      navigate('/campaigns');
    } else {
      loginWithRedirect();
    }
  };

  return (
    <section
      id="features"
      aria-label="Features for running your books"
      className="relative overflow-hidden bg-primary pt-12 pb-20 sm:py-24"
    >
      <div className="max-w-3xl mx-auto px-2 text-center xl:max-w-none">
        <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl md:text-5xl">
          Customize your notes how you want to.
        </h2>
        <p className="mt-6 text-lg tracking-tight text-blue-100">
          Your notes should work for you, and look great while doing it.
        </p>
      </div>

      <div className="mx-auto max-w-[95%] md:max-w-[80%] px-2 sm:px-4 mt-12 md:mt-16">
        <div className="space-y-16 md:space-y-20">
          {features.map((feature, index) => {
            const [ref, isVisible] = useIntersectionObserver();
            
            return (
              <div 
                key={feature.title}
                ref={ref}
                className={`flex flex-col ${index % 2 === 0 ? 'lg:flex-row' : 'lg:flex-row-reverse'} items-start gap-x-12 gap-y-8 transition-all duration-1000 delay-200 ease-out ${
                  isVisible 
                    ? 'opacity-100 translate-y-0' 
                    : 'opacity-0 translate-y-10'
                }`}
              >
                <div className="lg:w-3/5">
                  <div className="relative">
                    <div className={`overflow-hidden rounded-xl bg-transparent ${
                      feature.imageSize === 'large' ? 'w-full' : 
                      feature.imageSize === 'medium' ? 'w-3/4 mx-auto' : 
                      feature.isMultiImage ? 'w-full' : 'w-1/3 mx-auto'
                    }`}>
                      {feature.isMultiImage ? (
                        <div className="flex gap-4 justify-center p-4">
                          {feature.lightImage.map((image, idx) => (
                            <img 
                              key={idx}
                              src={isDarkTheme ? feature.darkImage[idx] : image}
                              alt={`${feature.title} ${idx + 1}`}
                              className="w-1/2 xl:w-2/5 rounded-md shadow-xl"
                            />
                          ))}
                        </div>
                      ) : (
                        <img 
                          src={isDarkTheme ? feature.darkImage : feature.lightImage}
                          alt={feature.title}
                          className={`rounded-md shadow-2xl ${
                            feature.imageSize === 'large' ? 'w-full' : 
                            feature.imageSize === 'medium' ? 'w-full' : 
                            'w-full'
                          }`}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="lg:w-2/5 lg:px-4">
                  <h4 className="text-sm font-semibold leading-8 tracking-wide text-accent uppercase flex items-center gap-2">
                    <feature.icon className="h-5 w-5 text-accent" aria-hidden="true" />
                    {feature.subtitle}
                  </h4>
                  <h3 className="mt-2 text-2xl font-display tracking-tight text-white sm:text-3xl">
                    {feature.title}
                  </h3>
                  <p className="mt-4 text-lg text-white">
                    {feature.description}
                  </p>
                  {feature.action && (
                    <ActionButton onClick={handleAction}>
                      {feature.action}
                    </ActionButton>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
