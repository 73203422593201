const LandingMention = ({ 
  children, 
  bgColor = 'accent', // 'accent' or 'primary' or any custom color
  textColor = 'black',
  isItalic = false,
  isBold = false,
  className = '' 
}) => {
  const baseStyles = "tag p-[0.2rem] rounded";
  const styles = [
    baseStyles,
    `bg-${bgColor}`,
    `text-${textColor}`,
    isItalic ? 'italic' : '',
    isBold ? 'font-bold' : 'font-medium',
    className
  ].filter(Boolean).join(' ');

  return (
    <span className={styles}>
      {children}
    </span>
  );
};

export default LandingMention; 