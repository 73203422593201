import React from 'react';
import { Page, Text, View, Document, StyleSheet, Svg, Path } from '@react-pdf/renderer';
import { ExportLibrary } from '../../utilities/ExportLibrary';
import { getIcon } from '../../utilities/IconMap';
import ReactDOMServer from 'react-dom/server';

// Create styles
const fontSize = 12;

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    padding: 20,
    paddingRight: 30,
    fontFamily: 'Helvetica',
  },
  header: {
    marginBottom: 4,
    paddingBottom: 4,
    borderBottom: 2,
    borderColor: '#333333',
  },
  title: {
    fontSize: fontSize * 1.33,  // 16px
    fontWeight: 'bold',
    color: '#000000',
    marginBottom: 4,
    textAlign: 'center',
  },  
  sessionHeader: {
    fontSize: fontSize * 1.17,  // 14px
    fontWeight: 'semi-bold',
    color: '#000000',
    marginBottom: 4,
    marginTop: 4,
  },
  content: {
    marginTop: 4,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  noteContainer: {
    backgroundColor: '#ffffff',
    padding: 1,
    paddingRight: 8,
    marginBottom: 2,
    breakInside: 'avoid',
  },
  noteText: {
    fontSize: fontSize,
    color: '#000000',
  },
  tag: {
    padding: '1 5',
    borderRadius: 2,
    marginHorizontal: 2,
    fontSize: fontSize,
  },
  noteTypeIcon: {
    padding: '1 5',
    borderRadius: 2,
    marginHorizontal: 2,
    fontSize: fontSize,
  },
  noteRow: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 2
  },
  iconContainer: {
    width: fontSize * 1.5,
    height: fontSize * 1.5,
    padding: 1,
    marginRight: 4,
  },
  subtitle: {
    fontSize: fontSize,
    color: '#666666',
    marginBottom: 8,
    textAlign: 'center',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: fontSize * 0.8,
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: '#666666',
  },
});

// Create Document Component
const PDFTemplate = ({ notes, keys, campaignName, campaignStartDate, noteTypes }) => {
  // Group notes by session
  const groupedNotes = notes.reduce((acc, note) => {
    const sessionId = note.SessionId;
    if (!acc[sessionId]) {
      acc[sessionId] = [];
    }
    acc[sessionId].push(note);
    return acc;
  }, {});

  // Sort sessions by SessionId
  const sortedSessions = Object.entries(groupedNotes).sort(([idA], [idB]) => 
    parseInt(idA) - parseInt(idB)
  );

  const getNoteTypeIcon = (noteTypeId) => {
    const noteType = noteTypes?.find(nt => nt.Id === noteTypeId);
    if (!noteType || noteType.Icon == "") {
      return <View style={styles.iconContainer} />;
    }
    
    const IconComponent = getIcon(noteType.Icon);
    if (!IconComponent) {
      return <View style={styles.iconContainer} />;
    }

    // Render the icon to SVG markup
    const markup = ReactDOMServer.renderToStaticMarkup(
      <IconComponent className="w-[20px] min-w-[20px]" />
    );

    // Create a temporary element to parse the SVG
    const div = document.createElement('div');
    div.innerHTML = markup;
    const svg = div.querySelector('svg');
    
        
    let pathData = null;

    // 1️⃣ Check if a <path> already exists
    const path = svg?.querySelector("path");
    if (path) {
      pathData = path.getAttribute("d");
    } else {
      let segments = [];

      // 2️⃣ Collect points from ALL <polyline> elements
      const polylines = svg?.querySelectorAll("polyline");
      if (polylines) {
        polylines.forEach((polyline) => {
          const points = polyline.getAttribute("points");
          if (points) {
            const pointsArray = points.trim().split(/\s+|,/); // Handle spaces or commas
            if (pointsArray.length >= 2) {
              let polylinePath = `M ${pointsArray[0]} ${pointsArray[1]}`;
              for (let i = 2; i < pointsArray.length; i += 2) {
                polylinePath += ` L ${pointsArray[i]} ${pointsArray[i + 1]}`;
              }
              segments.push(polylinePath);
            }
          }
        });
      }

      // 3️⃣ Collect points from ALL <line> elements
      const lines = svg?.querySelectorAll("line");
      if (lines) {
        lines.forEach((line) => {
          const x1 = line.getAttribute("x1");
          const y1 = line.getAttribute("y1");
          const x2 = line.getAttribute("x2");
          const y2 = line.getAttribute("y2");
          if (x1 && y1 && x2 && y2) {
            segments.push(`M ${x1} ${y1} L ${x2} ${y2}`);
          }
        });
      }

      // 4️⃣ Merge all segments into one path string
      if (segments.length > 0) {
        pathData = segments.join(" ");
      }
    }

    if (!pathData) {
      return <View style={styles.iconContainer} />;
    }

    return (
      <View style={styles.iconContainer}>
        <Svg viewBox="0 0 24 24" width={20} height={20}>
          <Path d={pathData} stroke="black" fill="none" />
        </Svg>
      </View>
    );
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <Document>
      {sortedSessions.map(([sessionId, sessionNotes], pageIndex) => (
        <Page key={sessionId} size="LETTER" style={styles.page}>
          <View style={styles.header}>
            {pageIndex === 0 && (
              <>
                <Text style={styles.title}>{campaignName}</Text>
                <Text style={styles.subtitle}>
                  Campaign started {formatDate(campaignStartDate)}
                </Text>
              </>
            )}
            <Text style={styles.sessionHeader}>
              Session {pageIndex + 1}: {sessionNotes[0]?.Title || ''}
            </Text>
          </View>
          
          <View style={styles.content}>
            {sessionNotes.map((note, index) => (
              <View key={index} style={styles.noteContainer}>
                <View style={styles.noteRow}>
                  {getNoteTypeIcon(note.NoteTypeId)}
                  {ExportLibrary.renderPDFNoteContent(
                    JSON.parse(note.NoteText),
                    keys,
                    styles
                  )}
                </View>
              </View>
            ))}
          </View>

          {/* Page number */}
          <Text style={styles.pageNumber}>
            {pageIndex + 1}
          </Text>
        </Page>
      ))}
    </Document>
  );
};

export default PDFTemplate; 